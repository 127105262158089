<template>
    <v-container fluid>
        <v-card>
            <v-card-text>
                <v-form ref="form" v-model="formValid" lazy-validation>
                    <v-text-field v-model="user.username" label="Felhasználónév" required>
                    </v-text-field>
                    <v-text-field v-model="user.email" label="E-mail" required></v-text-field>
                    <v-text-field v-model="user.name" label="Név" required></v-text-field>
                    <v-text-field v-model="user.password" type="password" label="Új jelszó">
                    </v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" style="width: 100px;" @click="onSubmit">Mentés</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import { AuthAPI } from '../../api/auth';

export default {
    name: "NewUser",
    data: () => ({
        user: {
            email: "",
            name: "",
        }
    }),
    mounted: function () {
        if (this.$route.params.id) {
            // Load user here
            AuthAPI.getUser(this.$route.params.id).then((response) => {
                this.user = response.data;
            });
            this.$store.commit("app/setTitle", `Felhasználó szerkesztése ${this.user.name}`);
        } else {
            this.$store.commit("app/setTitle", "Új felhasználó");
        }
    },
    methods: {
        onSubmit: function () {
            if (this.user.id) {
                // Létező user frissítése. 
                AuthAPI.updateUser(this.user.id, this.user).then((response) => {
                    this.$router.push({ name: "users.view", params: { id: response.data.id } });
                });
            }
            else {
                // Új user létrehozása.
                AuthAPI.createUser(this.user).then((response) => {
                    this.$router.push({ name: "users.view", params: { id: response.data.id } });
                });
            }
        }
    }
};
</script>